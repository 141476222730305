import axios from 'axios';
import Swal from 'sweetalert2'
import {
  GET_PDF,
  GET_JSON,
  GET_PENDING_DATA,
  GET_QA_DATA,
  GET_APPROVED_DATA,
  GET_AUTO_DATA,
  HI_ERROR,
  UPDATE_JSON,
  GET_QUESTION_ANSWER,
  SEND_TO_QA,
  CLEAR_TABLE,
  SEND_TO_BAD,
  DELETE_TEMPLATE,
  GET_HI_QUESTION_DATA,
  REMOVE_HI_QUESTION_DATA,
  GET_TRANSLATEPDF_DATA,
  GET_QUESTIONMODAL_ANSWER,
  REMOVE_QUESTIONMODAL_ANSWER,
  GET_BOUNDINGBOX_DATA,
  GET_BOUNDINGBOX_DATA_ANCHORTEXT,
  GET_SESSION_REPORT,
  GET_PENDIND_LINK_DATA,
  GET_COUNTRY_DATA,
  UPDATE_LINK,
  RESET_UPDATELINKLOADINGSTATUS,
  GET_CHATGPT_QUESTION_ANSWER,
  SET_SUBHEADING_HEADER,
  SET_SEARCH_DATA,
  SET_AUTOTAB_PAGINATION,
  GET_PROCESSED_DATA_FROM_CHATGPT,
  GET_HI_QUESTION_CHATGPT_DATA,
  REMOVE_HI_QUESTION_CHATGPT_DATA,
  GET_QUESTIONMODAL_ANSWER_FROM_CHATGPT,
  REMOVE_QUESTIONMODAL_CHATGPT_ANSWER,
  LOADING_CHATGPT_QUESTION_ANSWER,
  LOADING_QUESTION_ANSWER,
  LOADING_ANSWER_ERROR,
  CHATGPT_RESPONSE_ERROR,
  API_EXECUTION_COMPLETED,
  LOADING_TRANSLATE_PDF_ERROR,
  SET_FORM_DATA,
  GET_ALERT,
  SET_ALERT_TAB_PAGINATION,
  CLEAR_PENDING_TABLE,
  CLEAR_ALERT_TABLE,
  IS_REMOVING_REDUNTANT_TEMPLATES,
  CLEAR_PENDING_TRANSACTIONS,
  PENDING_TRANSACTIONS,
  GET_LIVE_EXTRACTION,
  CLEAR_APPROVED_TRANSACTIONS,
  GET_APPROVED_TRANSACTIONS,
  GET_TRANSACTION_EXTRACTION,
  APPROVE_TRANSACTION,
  TRANSACTION_SEND_TO_BAD,
  GET_COMPLETED_TRANSACTIONS,
  GET_QA_TRANSACTIONS,
  REDO_TRANSACTION
} from './types'; 

import { env as environment } from './environment';
axios.defaults.headers.common['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkaXJvbGFic0BnbWFpbC5jb20iLCJhcGlrZXkiOiIzNjE1NmNjODM5YmE5NzI3MmJjNDI2ZDc5YzcyYTQxZiIsImlhdCI6MTY1MTY3MDkzOX0.17jQ1btOzBpCObAxC7SBkI9rmXJML7j1UH_oJpVxcvb2tOHlXisQ3ouM6Tst9GjnuDIoF7rvpkqBRhGDu8Q4rQ";
// Get pdf
export const getPdfdata = () => async dispatch => {
  let json = {}
  try {
    const res = await axios.post(environment.getpdftemplate, json);
    console.log("getpdf" + res);
    console.log("get pdf action calledd")
    dispatch({
      type: GET_PDF,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get Alert data
export const getAlertdata = (limit) => async dispatch => {
  dispatch({ type: CLEAR_ALERT_TABLE });
  try {
    const res = await axios.post(environment.getUnprocessedSession,{limit});
    console.log("getAlert" + res);
    dispatch({
      type: GET_ALERT,
      payload: res.data.rows
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Pending data
export const getPendingdata = () => async dispatch => {
  console.log("header refresh check action")
  dispatch({ type: CLEAR_PENDING_TABLE });

  let json = { status: "pending" }
  try {
    const res = await axios.post(environment.getpdftemplate, json);
    console.log("getpdf" + res);
    dispatch({
      type: GET_PENDING_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Approved data
export const getQadata = () => async dispatch => {
  let json = { status: "QA" }
  try {
    const res = await axios.post(environment.getpdftemplate, json);
    console.log("getpdf" + res);
    dispatch({
      type: GET_QA_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get Approved data
export const getApproveddata = () => async dispatch => {
  let json = { status: "active" }
  try {
    const res = await axios.post(environment.getpdftemplate, json);
    console.log("getpdf" + res);
    dispatch({
      type: GET_APPROVED_DATA,
      GET_AUTO_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
  
};
//get pendinglink data
export const getPendingLinkData = () => async dispatch => {
  let json = { status: "pendinglink" }
  try {
    const res = await axios.post(environment.getpendinglink, json);
    console.log("getpdf" + res);
    dispatch({
      type: GET_PENDIND_LINK_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
    });
  }
};
//get country
export const getCountryData = () => async dispatch => {
  try {
    const res = await axios.get(environment.getcountrydata);
    console.log("countrydata" + res);
    dispatch({
      type: GET_COUNTRY_DATA,
      payload: res.data.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
    });
  }
};
//get auto data
export const getAutodata = (limit,search) => async (dispatch) => {
  // axios.defaults.headers.common['Authorization'] = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJkaXJvbGFic0BnbWFpbC5jb20iLCJhcGlrZXkiOiIzNjE1NmNjODM5YmE5NzI3MmJjNDI2ZDc5YzcyYTQxZiIsImlhdCI6MTY1MTY3MDkzOX0.17jQ1btOzBpCObAxC7SBkI9rmXJML7j1UH_oJpVxcvb2tOHlXisQ3ouM6Tst9GjnuDIoF7rvpkqBRhGDu8Q4rQ";
  console.log("getautodata called", limit)
  dispatch({ type: CLEAR_TABLE });
  // const config = {
  //   headers: {
  //     //'Content-Type': 'application/json',
  //     "Allow-Control-Allow-Origin": '*'
  //   }
  // };
  try {
    const res = await axios.get(environment.getautopdftojson + "?limit=" + limit+"&search="+search);
    console.log("getautodata" + JSON.stringify(res));
    dispatch({
      type: GET_AUTO_DATA,
      payload: res.data,
      limitforautotab: limit

    });

  }
  catch (err) {
    console.log(err);
    dispatch({
      type: HI_ERROR,

    });
  }
};


// Get post
export const getJsondata = (docid, tempid, url) => async dispatch => {
  let json = {
    "templteid": tempid,
    "pdffilepath": url,
    "docid": docid
  }//IN-eTPsPC
  try {
    const res = await axios.post(environment.testjson, json);
    console.log("pdfjson " + res);
    dispatch({
      type: GET_JSON,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const closeapproveDocument = () => async dispatch => {
  dispatch({
    type: GET_QA_DATA
  });
};
// Get post
export const updateJsondata = (updateJsondata, executedSuccessfully=null) => async dispatch => {
  console.log(updateJsondata);
  let email = localStorage.getItem('email');
  if(email){
    updateJsondata.lastModifiedBy=email;
  }
  // console.log("In updatejsondata:",updateJsondata);
  try {
    const res = await axios.post(environment.updatepdftemplate, updateJsondata);
    console.log("pdfjson " + res);
    if (updateJsondata.status != null) {
      if (updateJsondata.status === "pending") {
        dispatch(getPendingdata());
        dispatch(getApproveddata());
      }

    }
    dispatch({
      type: UPDATE_JSON,
      payload: res
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
  finally{
    dispatch({
      type: API_EXECUTION_COMPLETED
    })
    executedSuccessfully();
  }
};

export const redoTemplateId = (id, limit, json_version, apikey) => async dispatch => {
  let json = {
    "docid": id,
    "apikey": apikey,
    "redotemplate": true,
  }
  try {
    let res;
    if (json_version === "v2") {
      res = await axios.post(environment.pdftojson, json);
    } else {
      res = await axios.post(environment.pdftojsonv3, json);
    }
    console.log("pdfjson " + res);
    console.log("limit of auto tab" + limit);
    dispatch(getPendingdata());
  } catch (err) {
    console.log("in er")
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const getQuestionAnswer = (template, docid, location, templateId) => async dispatch => {
  dispatch({
    type: LOADING_QUESTION_ANSWER,
  });
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  let json = {
    "template": template,
    "docid": docid,
    "location": location,
    "templateId": templateId
  }

  try {
    const res = await axios.post(environment.getquestionanswer, JSON.stringify(json), config);
    console.log(res);
    dispatch({
      type: GET_QUESTION_ANSWER,
      payload: res.data
    });
  } catch (err) {
    Swal.fire({
      icon: 'error',
      title: 'getquestionanswer',
      text: 'getquestionanswer api failed to execute',
      confirmButtonText: 'OK',
      confirmButtonColor: '#4acfe0',
    });
    console.log("in er getquestionAnswer")
    dispatch({
      type:LOADING_ANSWER_ERROR ,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
// export const getlivequestionanswer = (template, docid, location, templateId) => async dispatch => {
//   const config = {
//     headers: {
//       'content-type': 'application/json'
//     }
//   }
//   let json = {
//     "template": template,
//     "docid": docid,
//     "location": location,
//     "templateId": templateId
//   }

//   try {
//     const res = await axios.post(environment.getlivequestionanswer, JSON.stringify(json), config);
//     console.log(res);
//     dispatch({
//       type: GET_QUESTION_ANSWER,
//       payload: res.data
//     });
//   } catch (err) {
//     console.log("in er getlivequestionAnswer")
//     dispatch({
//       type: HI_ERROR,
//       // payload: { msg: err.response.statusText, status: err.response.status }
//     });
//   }
// }

export const sendToQa = (qatempdata) => async dispatch => {

  try {
    const res = await axios.post(environment.updatepdftemplate, qatempdata);
    dispatch(getPendingdata());
    dispatch(getQadata());
    dispatch({
      type: SEND_TO_QA,
      payload: res
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Function to delete a particular template 
export const sendToBad = (templateid, template, docid, reasonforbaddoc) => async dispatch => {

  let json = {
    "templateid": templateid,
    "template": template,
    "docid": docid,
    "reason": reasonforbaddoc,
    "badtemplate": true
  }
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  try {
    const res = await axios.post(environment.deletetemplate, JSON.stringify(json), config);
    dispatch(getPendingdata());
    dispatch({
      type: SEND_TO_BAD,
      payload: res
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const sessionSendToBad = (docid, reasonforbaddoc) => async dispatch => {

  console.log("sessionSendToBad action called",docid,reasonforbaddoc)

  let json = {
    "docid": docid,
    "reason": reasonforbaddoc,
  }
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  try {
    const res = await axios.post(environment.callbackforbaddoc, JSON.stringify(json), config);
    dispatch(getAlertdata());
    // dispatch({
    //   type: SEND_TO_BAD,
    //   payload: res
    // });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const deleteTemplate = (templateid, template) => async dispatch => {
  let json = {
    "templateid": templateid,
    "template": template,
    "badtemplate": false
  }
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  try {
    const res = await axios.post(environment.deletetemplate, JSON.stringify(json), config);
    dispatch(getPendingdata());
    dispatch({
      type: DELETE_TEMPLATE,
      payload: res
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const deleteRedundantTemplate = (templateid,template,docid) => async dispatch => {
  let json = {
    "templateid": templateid,
    "template": template,
    "docid":docid,
    "badtemplate": false,
    "reCheckTemplate": true
  }
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  try {
    const res = await axios.post(environment.deletetemplate, JSON.stringify(json), config);
    dispatch(getPendingdata());
    // dispatch({
    //   type: DELETE_TEMPLATE,
    //   payload: res
    // });
  } catch (err) {
    console.log('err##',err)
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//gethiquestion
export const getHiQuestion = (field, country, link,category) => async dispatch => {
  let json = { field,country,link,category}
  try {
    const res = await axios.post(environment.gethiquestion, json);
    console.log("hi question awstextract" + res);
    dispatch({
      type: GET_HI_QUESTION_DATA,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const removeHiQuestion = () => async dispatch => {
  dispatch({
    type: REMOVE_HI_QUESTION_DATA
  });
};
export const removeHiQuestionChatGpt = () => async dispatch => {
  dispatch({
    type: REMOVE_HI_QUESTION_CHATGPT_DATA
  });
};
let errorHandled=false;
export const getTranslatedPdf = (docid) => async dispatch => {
  
  var translatejson = {
    targetlanguage: "en",
    docid: docid,
    filenumber: 1,
  };
  try {
    const res = await axios.post(environment.translatepdf, translatejson, {
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        "Authorization":"Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXlhc2hAZGlyby5pbyIsImFwaWtleSI6ImYxNTQxNTg0OWFlMGRjODVmNjE5ZjE5YTE0ZTIyYmMzIiwiaWF0IjoxNjY4NTk1NjIzfQ.AFKU1WbrlMLYejEDLdHp7lu3ZuS9jH4hnBYGIUSj9hyUWQ8dwOmZ26XXa6irDefN7JjEg3tLzCIzRFH2kWMpjw"
      },
    });
    dispatch({
      type: GET_TRANSLATEPDF_DATA,
      payload: res.data
    });
    console.log("getPdfTranslation:", res);
    
  } catch (error) {
    if (!errorHandled) {
      Swal.fire({
        icon: 'error',
        title: 'translatepdf',
        text: 'translatepdf api failed to execute',
        confirmButtonText: 'OK',
        confirmButtonColor: '#4acfe0',
      });
      dispatch({
        type: LOADING_TRANSLATE_PDF_ERROR,
      });
      errorHandled = true; // Set the flag to true to indicate that the error has been handled
    }
   
  }
  
};
export const getQuestionModalAnswer = (question, docid, location, templateId, template, field) => async dispatch => {
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  let json = {
    "question": question,
    "docid": docid,
    "location": location,
    "templateId": templateId,
    "template": template,
    "fieldname": field
  }
  console.log(json);
  try {
    const res = await axios.post(environment.getquestionmodalanswer, JSON.stringify(json), config);
    console.log(res);
    dispatch({
      type: GET_QUESTIONMODAL_ANSWER,
      payload: res.data
    });
  } catch (err) {
    console.log("in er getquestionAnswer")
    Swal.fire({
      icon: 'error',
      title: 'getquestionmodalanswer',
      text: 'getquestionmodalanswer api failed to execute',
      confirmButtonText: 'OK',
      confirmButtonColor: '#4acfe0',
      // confirmButtonColor: '#4acfe0',
    });
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
export const getQuestionModalAnswerFromChatgpt = (question, docid, location, templateId, template, field) => async dispatch => {
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  let json = {
    "question": question,
    "docid": docid,
    "location": location,
    "templateId": templateId,
    "template": template,
    "fieldname": field
  }
  console.log(json);
  try {
    const res = await axios.post(environment.getquestionmodalanswerfromchatgpt, JSON.stringify(json), config);
    console.log(res);
    dispatch({
      type: GET_QUESTIONMODAL_ANSWER_FROM_CHATGPT,
      payload: res.data
    });
  } catch (err) {
    console.log("in er getquestionAnswer")
    Swal.fire({
      icon: 'error',
      title: 'getquestionmodalanswerfromchatgpt',
      text: 'getquestionmodalanswerfromchatgpt api failed to fetch',
      confirmButtonText: 'OK',
      confirmButtonColor: '#4acfe0',
    });
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
export const isRemovingRedundantTemplate = (payload) => async dispatch => {
  dispatch({
    type: IS_REMOVING_REDUNTANT_TEMPLATES,
    payload
  })
}
export const removeQuestionModalAnswer = () => async dispatch => {
  dispatch({
    type: REMOVE_QUESTIONMODAL_ANSWER
  });
}
export const removeQuestionModalChatGptAnswer = () => async dispatch => {
  dispatch({
    type: REMOVE_QUESTIONMODAL_CHATGPT_ANSWER
  });
}
export const sendBoundingBoxData = (highlightTip) => async (dispatch) => {
  console.log("in sendboundingboxdata");
  dispatch({
    type: GET_BOUNDINGBOX_DATA,
    payload: highlightTip
  });

};
export const sendBoundingBoxDataAnchorText = (highlightTip) => async (dispatch) => {
  dispatch({
    type: GET_BOUNDINGBOX_DATA_ANCHORTEXT,
    payload: highlightTip
  });

};
export const getSessionReport = (docid) => async (dispatch) => {
  var json = { sessionid: docid };
  console.log("IN sessionreport:", docid);
  try {
    const res = await axios.post(environment.sessionstats, json);
    console.log("sessionstat:", res.data);
    dispatch({
      type: GET_SESSION_REPORT,
      payload: res.data,
    });
  } catch (err) {
    console.log("err in sessionreport", err);
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const updateLinkData = (req) => async (dispatch) => {
  console.log("IN updateLinkData:", req);
  try {
    const res = await axios.post(environment.updatelink, req);
    console.log("updateLinkData response:", res.data);
    dispatch({
      type: UPDATE_LINK,
      payload: res.data,
    });
    dispatch(getPendingLinkData())
  } catch (err) {
    console.log("err in updateLink", err);
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
export const removeUpdateLinkloadingstatus = () => async dispatch => {
  dispatch({
    type: RESET_UPDATELINKLOADINGSTATUS
  });
}
export const getChatGptQuestionAnswer = (template, docid, location, templateId) => async dispatch => {
  dispatch({
    type: LOADING_CHATGPT_QUESTION_ANSWER,
  });
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  let json = {
    "template": template,
    "docid": docid,
    "location": location,
    "templateId": templateId
  }

  try {
    const res = await axios.post(environment.getChatGptResponse, JSON.stringify(json), config);
    dispatch({
      type: GET_CHATGPT_QUESTION_ANSWER,
      payload: res.data
    });
  } catch (err) {
    Swal.fire({
      icon: 'error',
      title: 'getChatGptResponse',
      text: 'getChatGptResponse api failed to fetch',
      confirmButtonText: 'OK',
      confirmButtonColor: '#4acfe0',
    });
    console.log("in er getChatGptQuestionAnswer" + err)
    dispatch({
      type:CHATGPT_RESPONSE_ERROR ,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
export const setSubheading = (activetab) => async dispatch => {
  try {
    let tabname = "pending";
    console.log(activetab);
    switch (activetab) {
      case "1":
        tabname = "pending";
        break;
      case "2":
        tabname = "qa";
        break;
      case "3":
        tabname = "approved";
        break;
      case "4":
        tabname = "auto";
        break;
      case "5":
        tabname = "whitelist";
        break;
    }
    dispatch({
      type: SET_SUBHEADING_HEADER,
      payload: tabname
    });
  } catch (err) {
    console.log("in er setSubheading" + err)
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
export const setSearchData = (search) => async dispatch => {
  try {
    dispatch({
      type: SET_SEARCH_DATA,
      payload: search
    });
  } catch (err) {
    console.log("in er setSearchData" + err)
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
export const setAutoTabPaginationPageNo = (pageno) => async dispatch => {
  try {
    dispatch({
      type: SET_AUTOTAB_PAGINATION,
      payload: pageno
    });
  } catch (err) {
    console.log("in er setAutoTabPaginationPageNo" + err)
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}

export const setAlertTabPaginationPageNo = (pageno) => async dispatch => {
  try {
    dispatch({
      type: SET_ALERT_TAB_PAGINATION,
      payload: pageno
    });
  } catch (err) {
    console.log("in er setAlertTabPaginationPageNo" + err)
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
export const setAcknowlegementOfHiResponse = (id,updatetime,status,docid) => async dispatch => {
  console.log(id);
  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  let json = {
    id,updatetime,status,docid
  }
  let email = localStorage.getItem('email');
  if(email){
    json.lastModifiedBy=email;
  }
  console.log(json);
  try {
    const res = await axios.post(environment.updateHiAutoData, JSON.stringify(json), config);
    console.log("IN setAcknowlegementOfHiResponse "+ res);
    // dispatch({
    //   type: GET_CHATGPT_QUESTION_ANSWER,
    //   payload: res.data
    // });
  } catch (err) {
    console.log("in er setAcknowlegementOfHiResponse" + err)
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
export const getChatgptResponseFromPdf = (formData) => async dispatch => {
console.log(formData);
  try {
   let response= await axios.post(environment.getProcessedDataFromChatGpt, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({
      type: GET_PROCESSED_DATA_FROM_CHATGPT,
      payload: response.data
    });
  } catch (err) {
    console.log("in er getChatgptResponseFromPdf" + err)
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}
//---- Send logs to graylog ----
export const sendLogs = async (shortmsg, longmsg, email) => {
  console.log("In send logs");
	let url = environment.graylogurl;
	try {
			var logMessage = {
        short_message:shortmsg,
        timestamp: Date.now() / 1000,
        full_message:longmsg,
        app_name: "m26_jsonreview_react",
			_ENV: environment.env,
    };
    if(email){
logMessage.user=email;
    }
		const res = await axios.post(url, logMessage);
    console.log("Send Logs");
		console.log(res);
	} catch (err) {
		console.log(err);
		// dispatch({
		// 	type: ORG_ERROR,
		// 	payload: err.response,
		// });
	}
};
export const getHiQuestionChatGpt = (field, country, link,category) => async dispatch => {
  let json = { field,country,link,category}
  try {
    const res = await axios.post(environment.gethiquestionchatgpt, json);
    console.log("getHiQuestionChatGpt" + res);
    dispatch({
      type: GET_HI_QUESTION_CHATGPT_DATA,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

export const setFormData = (formData) => (dispatch) => {
  console.log('jhahahahahah:', formData);
  dispatch({
    type: SET_FORM_DATA,
    payload: formData,
  });
};

export const clearStore = (formData) => (dispatch) => {
  console.log("clear store action called")
  dispatch({
    type: "CLEAR_STORE",
    payload: formData,
  });

};

//Get Pending Transactions
export const getPendingTransactions = (limit = 0) => async dispatch => {
  console.log("pending transaction action called",environment.getPendingTransactions)
  dispatch({ type: CLEAR_PENDING_TRANSACTIONS});
  try {
    const res = await axios.post(environment.getPendingTransactions,{limit});
    console.log("getPendingTransactions" + res);
    dispatch({
      type: PENDING_TRANSACTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get Live Transaction Extraction
export const getLiveExtraction = (req) => async dispatch => {
  try {
    const res = await axios.post(environment.getLiveExtraction,req);
    console.log("getLiveExtraction" + res);
    dispatch({
      type: GET_LIVE_EXTRACTION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get Transaction Extraction
export const getTransactionExtraction = (formData) => async dispatch => {
  console.log(" transaction action called",environment.getLiveExtraction,formData)
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data; boundary=<calculated when request is sent>",
        // "Access-Control-Allow-Origin": "*",
        Authorization:
          "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJzdXlhc2hAZGlyby5pbyIsImFwaWtleSI6IjMwODg2ZGViZWQyMDMxMTczNTg4YTEzNzlmMWY5YmI3IiwiaWF0IjoxNjc3MTY4MDg2fQ.soZ2QbQaRwQxE0SExLRNYyDX5172ZfPXncAYHlLM46fQcIXQiyUCNSEsqPSzB8xJqgLNARc4cz9gdW-IBXlLdA",
      },
    };
    const res = await axios.post(environment.getTransaction,formData,config);
    console.log("getTransactionExtraction" + res);
    dispatch({
      type: GET_TRANSACTION_EXTRACTION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get Approved Transactions
export const getApprovedTransactions = (limit = 0) => async dispatch => {
  console.log("approved transaction action called",environment.getApprovedTransactions)
  dispatch({ type: CLEAR_APPROVED_TRANSACTIONS});
  try {
    const res = await axios.post(environment.getApprovedTransactions,{limit});
    console.log("getApprovedTransaction" + res);
    dispatch({
      type: GET_APPROVED_TRANSACTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get Completed Transactions
export const getCompletedTransactions = (limit = 0) => async dispatch => {
  console.log("completed transaction action called",environment.getCompletedTransaction)
  try {
    const res = await axios.post(environment.getCompletedTransaction,{limit});
    console.log("getCompletedTransaction" + res);
    dispatch({
      type: GET_COMPLETED_TRANSACTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Update Transaction Template Status
export const updateTransactionTemplate = (req) => async dispatch => {
console.log("approve transaction action called")
  try {
    const res = await axios.post(environment.updateTransactionTemplate,req);
    dispatch({
      type: APPROVE_TRANSACTION,
      payload: res.data
    });
}catch (err) {
  dispatch({
    type: HI_ERROR,
    // payload: { msg: err.response.statusText, status: err.response.status }
  });
}
finally{
  dispatch({
    type: API_EXECUTION_COMPLETED
  })
}
};

// Function to delete a particular transaction template 
export const transactionBadCallback = (req) => async dispatch => {

  const config = {
    headers: {
      'content-type': 'application/json'
    }
  }
  try {
    const res = await axios.post(environment.callbackforbadtransaction, JSON.stringify(req), config);
    dispatch({
      type: TRANSACTION_SEND_TO_BAD,
      payload: res
    });
    dispatch(getPendingTransactions());
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Get Completed Transactions
export const getQATransactions = (limit = 0) => async dispatch => {
  console.log("get QA transaction action called",environment.getCompletedTransaction)
  try {
    const res = await axios.post(environment.getQATransaction,{limit});
    console.log("getQATransaction" + res);
    dispatch({
      type: GET_QA_TRANSACTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

//Redo Transaction Template 
export const redoTransactionTemplate = (req) => async dispatch => {
  console.log("redo transaction action called",req)
    try {
      const res = await axios.post(environment.redoTransactionDoc,req);
      dispatch({
        type: REDO_TRANSACTION,
        payload: res.data
      });
  }catch (err) {
    dispatch({
      type: HI_ERROR,
      // payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
  };